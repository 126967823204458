import { useCallback, useState, useEffect } from "react";
import { Badge, styled } from "@mui/material";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  isObjectArrayControl,
  isPrimitiveArrayControl,
  RankedTester,
  Resolve,
  UISchemaElement,
} from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl, rankWith } from "../utils";

// Shared components from AssetList
import { ImageList, Item, ImageItem, DroppableItem } from "./AssetList";
import { Icons } from "../common/Icons";

const NumberItem = styled(Badge)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.8em",
  width: "20px",
}));

export interface Props extends ControlProps {
  data: string[];
  path: string;
  handleChange: (path: string, value: string[]) => void;
}

export function AssetSolutionListFieldRenderer(props: Props) {
  const [options, setOptions] = useState<Item[]>([]);
  const [restrictDuplicates, setRestrictDuplicates] = useState(false);
  const [ignoreOrder, setIgnoreOrder] = useState(false);
  const [solutionLength, setSolutionLength] = useState(0);
  const [currentValueSets, setCurrentValueSets] = useState<string[][]>([]);

  const {
    label,
    description,
    data = [],
    visible,
    uischema,
    config,
    path,
    handleChange,
  } = props;
  const {
    separator = "|",
    sourceField,
    restrictDuplicatesField,
    ignoreOrderField,
    solutionLengthField = 0,
    maxSolutions = 0,
  } = uischema.options || {};

  const encodeValue = useCallback(
    (values: string[][]) => {
      // Apply restrictions
      if (restrictDuplicates) {
        values = values.map((v) => [...new Set(v)]);
      }

      if (ignoreOrder) {
        values = values.map((v) => v.sort());
      }

      if (solutionLength > 0) {
        values = values.map((v) => v.slice(0, solutionLength));
      }

      // Return formatted output
      return values.map((v) => v.join(separator).trim()).filter(Boolean);
    },
    [restrictDuplicates, ignoreOrder, solutionLength, separator]
  );

  const handleAddItem = useCallback(
    (index: number, item: Item, replace?: number) => {
      const newValues = currentValueSets.map((v, i) => {
        if (index === i) {
          return replace !== undefined
            ? v.map((_, j) => (j === replace ? item.id : _))
            : [...v, item.id];
        }

        return v;
      });

      // handle new solution
      if (index === currentValueSets.length) {
        newValues.push([item.id]);
      }

      handleChange(path, encodeValue(newValues));
    },
    [currentValueSets, encodeValue, handleChange, path]
  );

  const handleRemoveItem = useCallback(
    (solutionIndex: number, itemIndex: number) => {
      const newValues = currentValueSets.map((v, i) =>
        solutionIndex === i ? v.filter((_, j) => j !== itemIndex) : v
      );

      handleChange(path, encodeValue(newValues));
    },
    [currentValueSets, encodeValue, handleChange, path]
  );

  useEffect(() => {
    setCurrentValueSets(data.map((v) => String(v).split(separator)));
  }, [data, separator]);

  // React to other fields in the context
  useEffect(() => {
    if (sourceField) {
      setOptions(Resolve.data(config.context.data || {}, sourceField) || []);
    }

    if (restrictDuplicatesField) {
      setRestrictDuplicates(
        Resolve.data(config.context.data || {}, restrictDuplicatesField)
      );
    }

    if (ignoreOrderField) {
      setIgnoreOrder(
        Resolve.data(config.context.data || {}, ignoreOrderField) || false
      );
    }

    if (solutionLengthField) {
      setSolutionLength(
        Resolve.data(config.context.data || {}, solutionLengthField) || 0
      );
    }
  }, [
    config.context.data,
    ignoreOrderField,
    restrictDuplicatesField,
    solutionLengthField,
    sourceField,
  ]);

  // Refresh and apply restrictions
  useEffect(() => {
    handleChange(path, encodeValue(currentValueSets));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restrictDuplicates,
    ignoreOrder,
    solutionLength,
    // currentValueSets // boom!,
    options.length,
    encodeValue,
    handleChange,
    path,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <FieldWrapper label={label} description={description} uiSchema={uischema}>
        {currentValueSets.map((value, solutionIndex) => (
          <ImageList key={solutionIndex}>
            {maxSolutions !== 1 && (
              <NumberItem>{1 + solutionIndex}.</NumberItem>
            )}
            {value.map((itemValue, itemIndex) => {
              const item = options.find((o) => o.id === String(itemValue));

              return (
                <DroppableItem
                  key={itemIndex}
                  uiSchema={uischema}
                  onDrop={(item) =>
                    handleAddItem(solutionIndex, item, itemIndex)
                  }
                >
                  {item ? (
                    <ImageItem
                      key={itemIndex}
                      item={item}
                      config={config}
                      onRemove={() =>
                        handleRemoveItem(solutionIndex, itemIndex)
                      }
                    />
                  ) : (
                    Icons.question
                  )}
                </DroppableItem>
              );
            })}
            {(!solutionLength || value.length < solutionLength) && (
              <DroppableItem
                uiSchema={uischema}
                onDrop={(item) => handleAddItem(solutionIndex, item)}
              >
                {Icons.add}
              </DroppableItem>
            )}
          </ImageList>
        ))}
        {(!maxSolutions || currentValueSets.length < maxSolutions) && (
          <ImageList>
            {currentValueSets.length > 0 && <NumberItem />}
            <DroppableItem
              uiSchema={uischema}
              onDrop={(item) => handleAddItem(currentValueSets.length, item)}
            >
              {Icons.add}
            </DroppableItem>
          </ImageList>
        )}
      </FieldWrapper>
    </>
  );
}

const tester: RankedTester = rankWith(20, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "AssetSolutionList") &&
    (isObjectArrayControl(uiSchema as UISchemaElement, schema, context) ||
      isPrimitiveArrayControl(uiSchema as UISchemaElement, schema, context))
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(AssetSolutionListFieldRenderer),
};
