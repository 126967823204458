import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import { type GamePluginDto } from "@shared/api-client";
import { type Asset } from "@shared/game-engine";
import { GamePluginType, type GamePluginSubtype } from "@shared/utils/plugins";

import { useBackend, usePersistedState } from "@app/hooks";
import { type EditorComponentDropItem } from "@app/types";
import { ComponentPickerNav, ComponentPickerGroup } from "@app/editor";
import { ToDoTiny } from "@app/components";
import { ComponetPickerFlatten } from "./ComponentPickerFlatten";

interface Props {
  builtin?: boolean;
  type?: GamePluginType;
  subtypes?: GamePluginSubtype[];
  uuids?: string[];
  onSelect?: (id: string, template: EditorComponentDropItem) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset) => void;
  mode?: "groups" | "nav" | "flatten";
  search?: boolean;
}

export function ComponentPicker(props: Props) {
  const { onSelect, onUpload, onDelete, builtin, subtypes, mode = "groups", uuids, search } = props;
  const { pluginManager } = useBackend();

  const [components, setComponents] = useState<GamePluginDto[] | null>(null);
  const [expanded, setExpanded] = usePersistedState<string | null>("component-picker-expanded");

  // Load components
  useEffect(() => {
    const fetchComponents = async () => {
      const response = await pluginManager.getList({
        path: "/",
        search: {
          type: GamePluginType.GAME_COMPONENT,
          builtin,
          subtype: subtypes,
          uuid: uuids
        }
      });
      setComponents(response.items);
      setExpanded(response.items[0].uuid);
    };

    fetchComponents();
  }, [builtin, pluginManager, setExpanded, subtypes, uuids]);

  // Auto-expand the first group
  useEffect(() => {
    if (!expanded && components && components.length > 0 && !expanded) {
      setExpanded(components[0].uuid);
    }
  }, [components, expanded, setExpanded]);

  if (!components) {
    return (
      <Box display='flex' alignContent='center' justifyContent='center' p={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (components.length === 0) {
    return <ToDoTiny />;
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch"
      }}
      className='here'
    >
      {mode === "groups" &&
        components.map((component) => (
          <ComponentPickerGroup
            key={component.uuid}
            title={component.name}
            component={component}
            onSelect={onSelect}
            onUpload={onUpload}
            onDelete={onDelete}
            expanded={component.uuid === expanded}
            onExpand={(expanded) => setExpanded(expanded ? component.uuid : null)}
          />
        ))}
      {mode === "nav" && (
        <ComponentPickerNav components={components} onSelect={onSelect} onUpload={onUpload} onDelete={onDelete} />
      )}
      {mode === "flatten" && (
        <ComponetPickerFlatten
          components={components}
          onSelect={onSelect}
          onUpload={onUpload}
          onDelete={onDelete}
          allowSearch={search}
        />
      )}
    </Box>
  );
}
