import { alpha, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester, rankWith } from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState } from "react";
import { isFileUploadControl } from "../utils";
import type { JsonSchema, JsonUISchema } from "../types";
import { Icons } from "../common/Icons";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

const DropZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "320px",
  height: "168px",
  border: `1px dashed ${theme.palette.divider}`,
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",

  "&.hasImage": {
    // checkboard data image
    background:
      "url('data:image/svg+xml;base64,DQo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIgMiI+DQogICAgPGc+DQogICAgCTxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmZmZmZmYiLz4NCiAgICAJPHJlY3QgeD0iMSIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI2VlZWVlZSIvPg0KICAgIAk8cmVjdCB4PSIwIiB5PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjZWVlZWVlIi8+DQogICAgCTxyZWN0IHg9IjEiIHk9IjEiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmZmZmZmYiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg==')",
  },

  "&.dropping": {
    " > .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      top: -5,
    },
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center center",
  },

  ":hover": {
    cursor: "pointer",
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
}));

const handleFileDrop = async (
  files: File[],
  onLoad: (reader: FileReader) => void
) => {
  const file = files[0];
  const reader = new FileReader();
  reader.onload = () => onLoad(reader);
  reader.readAsDataURL(file);
};

export function FileUploadFieldRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  handleChange,
}: Props) {
  const [currentValue, setCurrentValue] = useState<string>("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) =>
      handleFileDrop(files, (reader) => {
        handleChange(path, reader.result?.toString() || "");
      }),
  });

  useEffect(() => {
    setCurrentValue(data !== undefined ? data : "");
  }, [data]);

  if (!visible) {
    return null;
  }

  const classes: string[] = [
    isDragActive ? "dropping" : "",
    currentValue ? "hasImage" : "",
  ].filter(Boolean);

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <DropZone className={classes.join(" ")} {...getRootProps()}>
        {currentValue && <img src={currentValue} />}
        {!currentValue && (
          <>
            {Icons.photo}
            <p>Arrastra y suelta o haz click para buscar</p>
          </>
        )}
      </DropZone>
      <input {...getInputProps()} />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(20, (uischema, schema, context) =>
  isFileUploadControl(uischema as JsonUISchema, schema as JsonSchema, context)
);

export default {
  tester,
  renderer: withJsonFormsControlProps(FileUploadFieldRenderer),
};
