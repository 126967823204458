import { useCallback, useEffect, useMemo, useState } from "react";

import { useAppContext, useTranslation } from "@app/hooks";

import { Button, PageLoading, ProjectList } from "@app/components";
import { useBackend } from "@app/hooks";
import { useNavigate } from "@app/router";
import { type GameDefinitionDto, type ListDto } from "@shared/api-client";

import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GamepadIcon from "@mui/icons-material/Gamepad";

import { ProjectModal } from "@app/components/projects/ProjectModal";
import { GameStatus } from "@app/types";

export default function ProjectsPage() {
  const { setPageTitle, notify, confirm } = useAppContext();
  const { t } = useTranslation();
  const { gameManager } = useBackend();
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(0);
  const [allGames, setAllGames] = useState<ListDto<GameDefinitionDto> | undefined>();

  const [selection, setSelection] = useState<GameDefinitionDto | undefined>();
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  const doRefresh = useCallback(() => setRefresh((refresh) => refresh + 1), []);

  const playGame = useCallback((game: GameDefinitionDto) => {
    window.open(import.meta.env.VITE_PLAYER_URL.replace(":uuid", game.uuid), "player");
  }, []);

  const editGame = (game: GameDefinitionDto) =>
    navigate("/dashboard/projects/:uuid", {
      params: { uuid: game.uuid }
    });

  const deleteGame = useCallback(
    async (game: GameDefinitionDto) => {
      confirm({ title: t("projects.delete"), content: t("projects.deleteConfirm", { name: game.name }) }).then(
        async () => {
          await gameManager.delete({ path: "/:uuid", params: { uuid: game.uuid } });
          doRefresh();
        }
      );
    },
    [confirm, doRefresh, gameManager, t]
  );

  const cloneGame = useCallback(
    async (game: GameDefinitionDto) => {
      try {
        const newGame = await gameManager.post("/", { templateUuid: game.uuid });
        navigate("/dashboard/projects/:uuid", {
          params: { uuid: newGame.uuid }
        });
      } catch (error) {
        notify(String(error), "error");
        throw error;
      }
    },
    [gameManager, navigate, notify]
  );

  const openBanner = useCallback((game: GameDefinitionDto) => {
    setSelection(game);
    setSelectionModalOpen(true);
  }, []);

  useEffect(() => {
    gameManager
      .getList({ path: "/", params: { refresh } })
      .then(setAllGames)
      .catch((error) => notify(String(error), "error"));
  }, [gameManager, notify, refresh]);

  useEffect(() => {
    setPageTitle(t("projects.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  const publishedActions = useMemo(
    () => [
      {
        key: "refresh",
        icon: <ReplayIcon />,
        onClick: doRefresh
      }
    ],
    [doRefresh]
  );

  const draftActions = useMemo(
    () => [
      {
        key: "refresh",
        icon: <ReplayIcon />,
        onClick: doRefresh
      },
      {
        key: "create",
        icon: <AddIcon />,
        onClick: () => navigate("/dashboard/create")
      }
    ],
    [doRefresh, navigate]
  );

  if (!allGames) {
    return <PageLoading />;
  }

  const published = allGames.items.filter((game) => game.status === GameStatus.PUBLISHED);
  const drafts = allGames.items.filter((game) => game.status === GameStatus.DRAFT);

  return (
    <>
      {published.length ? (
        <ProjectList
          games={published}
          title={t("projects.published")}
          emtpyMessage={t("projects.noPublishedProjects")}
          onSelect={openBanner}
          itemHoverIcon={<GamepadIcon />}
          onEdit={editGame}
          onDelete={deleteGame}
          onClone={cloneGame}
          onOpenBanner={openBanner}
          actions={publishedActions}
        />
      ) : null}

      <ProjectList
        games={drafts}
        title={t("projects.nonPublished")}
        onSelect={editGame}
        itemHoverIcon={<EditIcon />}
        onEdit={editGame}
        onDelete={deleteGame}
        onClone={cloneGame}
        onOpenBanner={openBanner}
        actions={draftActions}
        emtpyMessage={
          <>
            {t("projects.noDrafts")}
            <br />
            <br />
            <Button variant='contained' onClick={() => navigate("/dashboard/create")}>
              {t(published.length ? "projects.createNew" : "projects.createFirst")}
            </Button>
          </>
        }
      />
      {selection && (
        <ProjectModal
          open={selectionModalOpen}
          onClose={() => {
            setSelectionModalOpen(false);
          }}
          game={selection}
          onEdit={() => editGame(selection)}
          onPlay={selection.status === GameStatus.PUBLISHED ? () => playGame(selection) : undefined}
        />
      )}
    </>
  );
}
