import { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "@app/router";

import { Button } from "@app/components";
import { PasswordResetForm, type PasswordResetFormData } from "@app/components";
import { useQuery, useBackend, useTranslation } from "@app/hooks";

export default function PasswordResetPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();
  const query = useQuery();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (data: PasswordResetFormData): Promise<boolean> => {
    setError("");
    try {
      await auth.post("/update-password", {
        token: query.get("token"),
        email: query.get("email"),
        password: data.password
      });

      setSuccess(true);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("passwordReset.error").toString());
      }

      return false;
    }
  };

  if (!success) {
    return (
      <>
        <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
          {t("passwordReset.title")}
        </Typography>

        <Typography variant='body1' sx={{ margin: theme.spacing(2, 0) }}>
          {t("passwordReset.message")}
        </Typography>

        <PasswordResetForm errors={error ? { root: error } : undefined} onSubmit={handleSubmit} />

        <Typography textAlign='center' sx={{ marginTop: theme.spacing(2) }}>
          <Link to='/auth/login'>{t("passwordReset.backToLogin")}</Link>
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("passwordReset.success")}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: theme.spacing(6) }}>
        {t("passwordReset.successMessage")}
      </Typography>

      <Link to='/auth/login'>
        <Button variant='contained' color='primary' fullWidth>
          {t("passwordReset.continue")}
        </Button>
      </Link>
    </>
  );
}
