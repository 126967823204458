import { useEffect, useState, type ReactElement, type ReactNode } from "react";
import { Button, alpha, styled } from "@mui/material";

const ModalTabsWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
}));

const TabSidePanel = styled("div")(({ theme }) => ({
  width: "250px",
  backgroundColor: alpha(theme.palette.divider, 0.035),
  display: "flex",
  flexShrink: 0,
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(4),
}));

const TabButton = styled(Button)(({ theme }) => ({
  textAlign: "left",
  justifyContent: "flex-start",
  textTransform: "none",
  width: "100%",
  color: theme.palette.text.primary,
  background: theme.palette.background.paper,
  borderRadius: "3px",
  padding: theme.spacing(2, 4),
  fontSize: "1rem",

  "&.active": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.1),
  },
}));

const TabContentBox = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  overflow: "auto",
  height: "100%",

  "& .MuiTypography-root:not(:last-child)": {
    marginBottom: theme.spacing(3),
  },
}));

export interface TabPanelProps<T> {
  id: T;
  icon?: ReactNode;
  title?: ReactNode;
  children?: ReactNode;
}

export function TabPanel<T>(props: TabPanelProps<T>) {
  const { children } = props;
  return <TabContentBox>{children}</TabContentBox>;
}

export interface ModalTabsProps<T> {
  activeTab?: T;
  children: ReactElement<typeof TabPanel>[] | ReactElement<typeof TabPanel>;
  onTabChange?: (tab: T) => void;
}

export function ModalTabs<T>({
  children,
  activeTab,
  onTabChange,
}: ModalTabsProps<T>) {
  const [currentTab, setCurrentTab] = useState<T | undefined>(activeTab);

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  const childrenArr = Array.isArray(children) ? children : [children];

  const tabs = childrenArr.map((child) => {
    const tabProps = child.props as unknown as TabPanelProps<T>; // TODO: Fix this type casting
    const selected = currentTab === tabProps.id;

    if (!tabProps.title) {
      return null;
    }

    return (
      <TabButton
        key={String(tabProps.id)}
        onClick={() => {
          if (currentTab === tabProps.id) {
            return;
          }

          onTabChange ? onTabChange(tabProps.id) : setCurrentTab(tabProps.id);
        }}
        startIcon={tabProps.icon}
        className={selected ? "active" : undefined}
      >
        {tabProps.title}
      </TabButton>
    );
  });

  const currentTabContent = childrenArr.find((tab) => {
    const tabProps = tab.props as unknown as TabPanelProps<T>; // TODO: Fix this type casting
    return tabProps.id === currentTab;
  });

  return (
    <ModalTabsWrapper>
      <TabSidePanel>{tabs}</TabSidePanel>
      <TabContentBox>{currentTabContent}</TabContentBox>
    </ModalTabsWrapper>
  );
}
