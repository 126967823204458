import { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "@app/router";

import { Button } from "@app/components";
import { useBackend, useTranslation } from "@app/hooks";
import { PasswordRecoveryForm, type PasswordRecoveryFormData } from "@app/components";

export default function PasswordRecoveryPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (data: PasswordRecoveryFormData): Promise<boolean> => {
    setError("");
    try {
      await auth.post("/reset-password", {
        email: data.email
      });

      setSuccess(true);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("passwordRecovery.error").toString());
      }

      return false;
    }
  };

  if (!success) {
    return (
      <>
        <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
          {t("passwordRecovery.title")}
        </Typography>

        <Typography variant='body1' sx={{ margin: theme.spacing(2, 0) }}>
          {t("passwordRecovery.message")}
        </Typography>

        <PasswordRecoveryForm errors={error ? { root: error } : undefined} onSubmit={handleSubmit} />

        <Typography textAlign='center' sx={{ marginTop: theme.spacing(2) }}>
          <Link to='/auth/login'>{t("passwordRecovery.backToLogin")}</Link>
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("passwordRecovery.success")}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: theme.spacing(6) }}>
        {t("passwordRecovery.successMessage")}
      </Typography>

      <Link to='/auth/login'>
        <Button variant='contained' color='primary' fullWidth>
          {t("passwordRecovery.continue")}
        </Button>
      </Link>
    </>
  );
}
