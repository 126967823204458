import { useState, useCallback, useEffect, useRef } from "react";
import { Box, Card, IconButton, Tooltip, Typography, alpha, styled } from "@mui/material";

import { Prompt } from "@app/components";
import { useAppContext, useHorizontalScroll, useTranslation } from "@app/hooks";

import { DEFAULT_SCREEN } from "@shared/game-engine";
import { type GameLevelTreeDto } from "@shared/api-client";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DeleteIcon from "@mui/icons-material/Delete";
import CloneIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import { useEditor } from "./useEditor";
import { type EditMode } from "./types";

const COMPONENT_HEIGHT = 80;

/**
 * Flatten the level tree into a list of tuples with the level id and the level data
 *
 * @param levelIds
 * @param tree
 * @returns
 */

function levelSorter(levelIds: string[], tree?: GameLevelTreeDto) {
  if (!tree) {
    return levelIds;
  }

  const pending = new Set(levelIds);
  const sorted: string[] = [];

  const visit = (node: GameLevelTreeDto) => {
    if (!node.levelId || !pending.has(node.levelId)) {
      return;
    }

    pending.delete(node.levelId);
    sorted.push(node.levelId);

    if (node.children) {
      node.children.forEach(visit);
    }
  };

  visit(tree);

  return [...sorted, ...pending];
}

/**
 * Check if a level is referenced in the tree
 *
 * @param levelId
 * @param tree
 * @returns
 */
function isLevelReferenced(levelId: string, tree?: GameLevelTreeDto): boolean {
  if (!tree) {
    return false;
  }

  if (tree.levelId === levelId) {
    return true;
  }

  return tree.children?.some((child) => isLevelReferenced(levelId, child)) || false;
}

interface Props {
  mode: EditMode;
}

const LevelsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "stretch",
  height: "100%",

  "&.overlays": {
    BackgroundColor: alpha(theme.palette.primary.main, 0.2)
  }
}));

const Actions = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.divider}`
}));

const ScrollContainer = styled("div")(() => ({ theme }) => ({
  flex: 1,
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  scrollbarColor: `${theme.palette.primary.dark} transparent`
}));

const LevelList = styled(Box)(() => ({
  margin: 0,
  height: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap"
}));

const LevelItem = styled(Box)(({ theme }) => ({
  height: "100%",
  flex: "0 0 auto",
  padding: theme.spacing(2, 2),

  transition: theme.transitions.create(["backgroundColor", "transform"], {
    duration: theme.transitions.duration.shortest
  }),

  "&.is-selected": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "-2px",

    ">.MuiCard-root:hover": {
      transform: "none"
    }
  },

  "&.has-reference .level-card": {
    backgroundColor: alpha(theme.palette.warning.light, 0.5)
  },

  "&.is-start .level-card": {
    backgroundColor: alpha(theme.palette.success.light, 0.5),
    fontWeight: "bold"
  },

  "&.is-finish .level-card": {
    backgroundColor: alpha(theme.palette.success.light, 0.5)
  },

  "&.is-game-over .level-card": {
    backgroundColor: alpha(theme.palette.error.light, 0.5)
  }
}));

const LevelCard = styled(Card)(({ theme }) => ({
  height: `${COMPONENT_HEIGHT}px`,
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  cursor: "pointer",

  transition: theme.transitions.create(["transform", "backgroundColor"], {
    duration: theme.transitions.duration.shortest
  }),

  "&:hover": {
    transform: "scale(1.03)"
  }
}));

const LevelFooter = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
}));

const LevelImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain"
}));

export function LevelPicker({ mode }: Props) {
  const { notify, confirm } = useAppContext();
  const { level, overlay, gameData, gameDataInfo, setLevel } = useEditor();
  const { t, language } = useTranslation();
  const scroll = useHorizontalScroll();
  const selectedRef = useRef<HTMLDivElement>(null);

  const [cloneLevel, setCloneLevel] = useState<string>();
  const [renameLevel, setRenameLevel] = useState<string>();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [sortedLevels, setSortedLevels] = useState<string[]>([]);

  const selected = mode === "overlay" ? level?.data?.overlay : level?.id;
  const levelTree = gameDataInfo?.levelTree || undefined;
  const targetLayer = mode === "overlay" ? overlay : level;

  useEffect(() => {
    if (mode === "overlay") {
      setSortedLevels(Object.keys(gameData?.overlays || {}));
    } else {
      setSortedLevels(levelSorter(Object.keys(gameData?.levels || {}), levelTree));
    }
  }, [gameData?.levels, gameData?.overlays, levelTree, mode]);

  useEffect(() => {
    if (selected && selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [levelTree, gameData, selected]);

  const handleCreateLevel = useCallback(
    async (id: string) => {
      if (!id) return;

      try {
        await targetLayer?.create(id, {
          copyFrom: cloneLevel,
          // Populate current overlay to the new level
          overlay: mode === "level" ? level?.data?.overlay : undefined
        });

        setShowCreateModal(false);
      } catch (error) {
        notify(t("editor.errors.createLevelError", { error }), "error");
      }
    },
    [cloneLevel, level?.data?.overlay, mode, notify, t, targetLayer]
  );

  const handleRenameLevel = useCallback(
    async (newId: string) => {
      if (!newId || !renameLevel) return;

      try {
        await targetLayer?.rename(renameLevel, newId);
        setRenameLevel(undefined);
      } catch (error) {
        notify(t("editor.errors.renameLevelError", { error }), "error");
      }
    },
    [renameLevel, targetLayer, notify, t]
  );

  const handleDeleteLevel = useCallback(
    async (id: string) => {
      try {
        const isReferenced = isLevelReferenced(id, levelTree);

        await confirm({
          title: t("editor.deleteLevel"),
          content: t(isReferenced ? "editor.deleteLevelConfirmWithWarning" : "editor.deleteLevelConfirm", { id })
        });

        await targetLayer?.remove(id);
      } catch (error) {
        if (!error) {
          // User cancelled
          return;
        }

        notify(t("editor.deleteError", { error }), "error");
      }
    },
    [levelTree, confirm, t, targetLayer, notify]
  );

  const screen = gameData?.screen || DEFAULT_SCREEN;

  return (
    <LevelsContainer className={mode}>
      <Actions>
        <Tooltip title={t("editor.createLevel")}>
          <IconButton aria-label={t("editor.createLevel").toString()} onClick={() => setShowCreateModal(true)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        <Box flexGrow={1} />
      </Actions>
      <ScrollContainer ref={scroll}>
        <LevelList>
          {sortedLevels.map((id, index) => {
            const level = mode === "overlay" ? gameData?.overlays?.[id] : gameData?.levels?.[id];

            if (!level) {
              return null;
            }

            const isReferenced = isLevelReferenced(id, levelTree);
            const isStartLevel = gameData?.gameflow?.initialLevelId === id;
            const isGameOverLevel = gameData?.gameflow?.gameOverLevelId === id;
            const isFinishLevel = gameData?.gameflow?.finishLevelId === id;
            const isProtected = isStartLevel || isGameOverLevel || isFinishLevel;

            const classes = [
              id === selected && "is-selected",
              isReferenced && "has-reference",
              isStartLevel && "is-start",
              isGameOverLevel && "is-game-over",
              isFinishLevel && "is-finish"
            ].filter(Boolean) as string[];

            return (
              <LevelItem key={id} className={classes.join(" ")} ref={id === selected ? selectedRef : undefined}>
                <LevelCard
                  onClick={() => setLevel(id)}
                  sx={{ width: (COMPONENT_HEIGHT / screen.height) * screen.width }}
                  className='level-card'
                >
                  {level._thumbnail ? (
                    <LevelImage src={level._thumbnail} alt={id} />
                  ) : (
                    <QuestionMarkIcon fontSize='large' sx={{ opacity: 0.3 }} />
                  )}
                </LevelCard>
                <LevelFooter sx={{ zoom: 0.8 }}>
                  <Typography variant='body1' align='left'>
                    {1 + index} | {level.translations?.[language]?.name || id}
                  </Typography>
                  <Box>
                    <IconButton title={t("editor.renameLevel").toString()} onClick={() => setRenameLevel(id)}>
                      <EditIcon fontSize='small' color='disabled' />
                    </IconButton>
                    <IconButton
                      title={t("editor.cloneLevel").toString()}
                      onClick={() => {
                        setCloneLevel(id);
                        setShowCreateModal(true);
                      }}
                    >
                      <CloneIcon fontSize='small' color='disabled' />
                    </IconButton>
                    {!isProtected && (
                      <IconButton
                        onClick={() => handleDeleteLevel(id)}
                        color='error'
                        title={t("editor.deleteLevel").toString()}
                      >
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    )}
                  </Box>
                </LevelFooter>
              </LevelItem>
            );
          })}
          <Box flexBasis='90px' flexGrow={0} flexShrink={0} />
        </LevelList>
      </ScrollContainer>
      <Prompt
        open={showCreateModal}
        title={cloneLevel ? t("editor.cloneLevel").toString() : t("editor.createLevel").toString()}
        message={cloneLevel ? t("editor.cloneLevelMessage", { id: cloneLevel }) : t("editor.createLevelMessage")}
        onSubmit={handleCreateLevel}
        initialValue={cloneLevel?.replace(/-\d+$/, (_, n) => `-${parseInt(n, 10) + 1}`)}
        onCancel={() => {
          setShowCreateModal(false);
          setCloneLevel(undefined);
        }}
        confirmText={cloneLevel ? t("common.clone").toString() : t("common.create").toString()}
      />
      <Prompt
        open={!!renameLevel}
        title={t("editor.renameLevel").toString()}
        message={t("editor.renameLevelMessage", { id: renameLevel })}
        initialValue={renameLevel}
        onSubmit={handleRenameLevel}
        onCancel={() => setRenameLevel(undefined)}
        confirmText={t("common.rename").toString()}
      />
    </LevelsContainer>
  );
}
