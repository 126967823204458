import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";

import { type GamePluginDto } from "@shared/api-client";
import { ComponentContext } from "@shared/game-player";

import { type EditorComponentDropItem } from "@app/types";

import { ComponentPickerList } from "./ComponentPickerList";
import { type Asset } from "@shared/game-engine";
import { useEditor } from "../useEditor";
import { extendTemplatesFromAssets } from "./utils";
import { ComponentUpload } from "./ComponentUpload";
import { ComponentTrash } from "./ComponentTrash";
import { useDrop } from "react-dnd";
import { GamePluginType } from "@shared/utils/plugins";
import { TextField } from "@app/components";
import { useTranslation } from "@app/hooks";
import { mergeDeep } from "@app/utils";

import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  components: GamePluginDto[];
  onSelect?: (id: string, data: EditorComponentDropItem) => void;
  onUpload?: (files: File[]) => void;
  onDelete?: (asset: Asset) => void;
  allowSearch?: boolean;
}

function normalize(str: string) {
  return str
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .trim()
    .toLowerCase();
}

export function ComponetPickerFlatten(props: Props) {
  const { components, onSelect, onUpload, onDelete, allowSearch } = props;
  const [search, setSearch] = useState<string>("");
  const [items, setItems] = useState<EditorComponentDropItem[] | null>();
  const [filteredItems, setFilteredItems] = useState<EditorComponentDropItem[] | null>();
  const [loading, setLoading] = useState(0);
  const [dragging, setDragging] = useState(false);
  const { gameAssets } = useEditor();
  const { ts } = useTranslation();

  const {
    plugins: { getPluginManifest }
  } = useContext(ComponentContext);

  // Drop component (trash)
  const [, drop] = useDrop<EditorComponentDropItem>(() => ({
    accept: GamePluginType.GAME_COMPONENT,
    drop: (item, monitor) => {
      if (item.asset && monitor.isOver({ shallow: true })) {
        // Delete the item
        onDelete?.(item.asset);
      }
    }
  }));

  // Load component manifests and expand templates
  useEffect(() => {
    setItems(null);
    setLoading(0);

    let cancelled = false;

    components.forEach((component) => {
      setLoading((prevLoading) => prevLoading + 1);
      getPluginManifest(component.uuid, component.version.toString()).then((manifest) => {
        if (cancelled) {
          return;
        }

        if (!manifest) {
          setLoading((prevLoading) => prevLoading - 1);
          return;
        }

        const templates = manifest?.templates || [];
        const templateDefaults = manifest?.templateDefaults || {};

        const items: EditorComponentDropItem[] = templates.map((template) => ({
          name: template.name || component.name || manifest.name,
          thumbnail: template.thumbnail || manifest.thumbnail || component.thumbnail,
          component,
          manifest,
          template: mergeDeep(templateDefaults, template)
        }));

        // Append assets as templates
        if (gameAssets) {
          items.push(...extendTemplatesFromAssets(component, manifest, gameAssets));
        }

        setItems((prevItems) => [...(prevItems || []), ...items].sort((a, b) => a.name.localeCompare(b.name)));
        setLoading((prevLoading) => prevLoading - 1);
      });
    });

    return () => {
      cancelled = true;
      setLoading(0);
    };
  }, [components, gameAssets, getPluginManifest]);

  useEffect(() => {
    if (allowSearch && search && items) {
      setFilteredItems(
        items.filter((item) => {
          const haystack = `${item.component.name} ${item.component.description} ${item.name}`;
          return normalize(haystack).includes(normalize(search));
        })
      );
    } else {
      setFilteredItems(items);
    }
  }, [search, items, allowSearch]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, []);

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <IconButton onClick={handleClearSearch} size='small'>
          {<ClearIcon />}
        </IconButton>
      )
    }),
    [handleClearSearch]
  );

  return (
    <Box display='flex' flexDirection='column' alignContent='stretch' alignItems='stretch' height='100%'>
      {allowSearch && (
        <TextField
          variant='outlined'
          size='small'
          placeholder={ts("common.search")}
          sx={{ mb: 2 }}
          value={search}
          inputProps={inputProps}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      )}
      {onUpload && !dragging && <ComponentUpload onDrop={onUpload} />}
      {onDelete && dragging && <ComponentTrash ref={drop} />}
      <Box flex={1} overflow='auto'>
        {filteredItems ? (
          <ComponentPickerList
            items={filteredItems}
            onSelect={onSelect}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
          />
        ) : null}
        {loading > 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
