import { ReactNode } from "react";

import { Modal, Box, styled, Typography, IconButton } from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const ModalBox = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate(-50%, 0)",
  maxWidth: "min(90vw, 980px)",
  maxHeight: "90%",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  outline: "none",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

export const TitleBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  alignContent: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),

  h6: {
    background: theme.palette.divider,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ModalContentBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflow: "auto",

  p: theme.spacing(4),

  "&.with-tabs": {
    p: 0,
  },
}));

interface PropertiesModalProps {
  title: ReactNode;
  children: ReactNode;
  open?: boolean;
  onClose?: () => void;
  withTabs?: boolean;
  footer?: ReactNode;
}

export const ModalButtonBar = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.divider}`,
  width: "100%",
}));

export function PropertiesModal(props: PropertiesModalProps) {
  return (
    <Modal open={props.open || false} onClose={props.onClose}>
      <ModalBox className={props.open ? "MuiModal-open" : ""}>
        <TitleBox>
          <Typography variant="h6">{props.title}</Typography>
          <IconButton onClick={props.onClose}>
            <HighlightOffIcon />
          </IconButton>
        </TitleBox>
        <ModalContentBox className={props.withTabs ? "with-tabs" : ""}>
          {props.children}
        </ModalContentBox>
        {props.footer && <ModalButtonBar mt={0}>{props.footer}</ModalButtonBar>}
      </ModalBox>
    </Modal>
  );
}
