import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
  type ReactNode,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { alpha, Box, styled } from "@mui/material";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester, UISchemaElement } from "@jsonforms/core";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";

import { FieldWrapper } from "../common/FieldWrapper";
import { isArrayOfType, isCustomControl, rankWith } from "../utils";
import CloseCirleImg from "../assets/close-circle.svg";

export interface Item {
  id: string;
  asset: string;
  name?: string;
}

export interface Props extends ControlProps {
  data: Item[];
  path: string;
  handleChange: (path: string, value: string[]) => void;
}

export const ImageList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const StyledImageItem = styled(Box)(({ theme }) => ({
  display: "block",
  width: "40px",
  height: "40px",
  background: "#ffffff",
  borderRadius: "3px",
  border: theme.palette.divider,
  cursor: "default",
  userSelect: "none",
  userDrag: "none",
  webkitUserDrag: "none",
  mozUserDrag: "none",
  overflow: "hidden",

  "> img": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },

  "&[draggable]": {
    cursor: "grab",
    userDrag: "element",
    webkitUserDrag: "element",
    mozUserDrag: "element",
  },

  "&.error": {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "> svg": {
      opacity: 0.5,
    },
  },

  "&.removable": {
    position: "relative",

    "&:hover:after": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: "4px",
      background: `url(${CloseCirleImg})`,
      backgroundSize: "30px",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundColor: alpha(theme.palette.error.main, 0.5),
      cursor: "pointer",
    },
  },
}));

export const DroppableBox = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#ffffff",
  borderRadius: "5px",
  borderColor: theme.palette.divider,
  borderStyle: "dashed",
  borderWidth: "1px",

  "&.highlight": {
    borderColor: theme.palette.primary.main,
  },

  "& svg": {
    opacity: 0.3,
  },
}));

export interface DesirableConfig {
  context?: {
    editorContext?: {
      renderAssetUrl: (url: string, obj?: Record<string, unknown>) => string;
    };
    manifest?: {
      type: string;
      id: string;
    };
  };
}

export const ImageItem = forwardRef(function (
  props: {
    item: Item;
    config: DesirableConfig;
    onRemove?: () => void;
  },
  forwardedRef: ForwardedRef<HTMLImageElement>
) {
  const { item, config, onRemove } = props;
  const [error, setError] = useState(false);

  // Translate asset path to URL
  const pluginType = config.context?.manifest?.type;
  const pluginId = config.context?.manifest?.id;
  const src =
    config.context?.editorContext?.renderAssetUrl?.(item.asset, {
      pluginId,
      pluginType,
    }) || item.asset;

  return (
    <StyledImageItem
      ref={forwardedRef}
      onClick={onRemove}
      onError={() => setError(true)}
      className={[onRemove ? "removable" : "", error ? "error" : ""]
        .filter(Boolean)
        .join(" ")}
    >
      {!error && <img src={src} alt={item.name} />}
      {error && <BrokenImageIcon color="error" />}
    </StyledImageItem>
  );
});

export function DraggableImageItem({
  item,
  uischema,
  config,
}: {
  item: Item;
  uischema: UISchemaElement;
  config: DesirableConfig;
}) {
  // Drag component
  const [, drag] = useDrag<Item>(() => ({
    type: uischema.options?.draggableId,
    item: () => item,
  }));

  return <ImageItem item={item} config={config} ref={drag} />;
}

export function DroppableItem({
  uiSchema,
  onDrop,
  children,
}: {
  uiSchema: UISchemaElement;
  onDrop: (item: Item) => void;
  children?: ReactNode;
}) {
  const [droppedItem, setDroppedItem] = useState<Item | undefined>();

  // Drag component
  const [{ isDragging }, drop] = useDrop<
    Item,
    unknown,
    { isDragging: boolean }
  >(() => ({
    accept: uiSchema.options?.draggableId,
    drop: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        setDroppedItem(item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (droppedItem) {
      setDroppedItem(undefined);
      onDrop(droppedItem);
    }
  }, [droppedItem, onDrop]);

  return (
    <DroppableBox ref={drop} className={isDragging ? "highlight" : ""}>
      {children}
    </DroppableBox>
  );
}

export function AssetListFieldRenderer(props: Props) {
  const { label, description, data = [], visible, uischema, config } = props;

  if (!visible) {
    return null;
  }

  // TODO: Make this editable

  const isDraggable = !!uischema.options?.draggableId;

  return (
    <>
      <FieldWrapper label={label} description={description} uiSchema={uischema}>
        <ImageList>
          {data.map((item) => {
            return isDraggable ? (
              <DraggableImageItem
                key={item.id}
                item={item}
                uischema={uischema}
                config={config}
              />
            ) : (
              <ImageItem key={item.id} item={item} config={config} />
            );
          })}
        </ImageList>
      </FieldWrapper>
    </>
  );
}

const tester: RankedTester = rankWith(20, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "AssetList") &&
    isArrayOfType("object", uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(AssetListFieldRenderer),
};
