import { MenuItem, Select } from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isEnumControl,
  isOneOfControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState } from "react";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function SelectFieldRenderer(props: Props) {
  const {
    label,
    description,
    data,
    schema,
    path,
    handleChange,
    visible,
    uischema,
    enabled,
  } = props;
  const isRequired = schema.required ? true : false;
  const [currentValue, setCurrentValue] = useState<string>("");

  useEffect(() => {
    setCurrentValue(data || "");
  }, [data]);

  if (!visible) {
    return null;
  }

  const options: { title: string; const: string }[] =
    schema.oneOf?.map((option) => ({
      title: option.title || option.const || "",
      const: option.const || "",
    })) ||
    schema.enum?.map((value) => ({ title: value, const: value })) ||
    [];

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <Select
        fullWidth
        value={currentValue}
        onChange={(ev) => handleChange(path, ev.target.value)}
        disabled={!enabled}
      >
        {!isRequired && (
          <MenuItem key="{empty}" value="">
            {isRequired ? "Seleccione una opción" : "Ninguno"}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.const} value={option.const}>
            {option.title}
          </MenuItem>
        ))}
        {data && !options.find((item) => item.const === data) && (
          <MenuItem key={data} value={data}>
            {data}
          </MenuItem>
        )}
      </Select>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(11, (uiSchema, schema, context) => {
  return isEnumControl(uiSchema, schema, context) ||
    isOneOfControl(uiSchema, schema, context)
    ? true
    : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(SelectFieldRenderer),
};
