import { hasAction, type GameElement } from "@shared/game-engine";
import { mergeGameData } from "@app/utils";

import { type UpdateFlags, type ElementUpdate } from "./types";

export function findAndUpdate(layout: GameElement[], updates: ElementUpdate[], flags: UpdateFlags): GameElement[] {
  const newIndexes: Record<string, number> = {};
  const updatedLayout = layout.reduce<GameElement[]>((result, element, index) => {
    // Store the original index for later sorting
    newIndexes[element.id] = index;
    const update = updates.find((update) => update.id === element.id);

    if (!update) {
      // Deep update
      if (element.layout) {
        return [...result, { ...element, layout: findAndUpdate(element.layout, updates, flags) }];
      }

      // No changes
      return [...result, element];
    }

    const { id, _delete, _order, _replace, ...currentUpdate } = update;
    const { enabled, layout, properties, translations, stage } = currentUpdate;

    if (_delete) {
      if (hasAction(element, /^go-to-level\s/)) {
        // Force refresh the tree
        flags.refreshGameInfo = true;
      }

      return result;
    }

    if (_order) {
      // Displace the index so we can sort it later.
      // It does displace the _order value +/- .1 so we avoid
      // hitting the same integer value from previous/next elements.
      // Examples (all of them from [1,2,3,4,5]):
      // - Element 2 with _order -1. It results: [1,0.9,3,4,5]
      // - Element 2 with _order 1. It results: [1,3.1,3,4,5]
      // - Element 2 with _order 2. It results: [1,4.1,3,4,5]
      newIndexes[id] = index + _order + (_order > 0 ? 0.1 : -0.1);
    }

    // Detect action changes so it forces a game info update
    if (hasAction(update, /^go-to-level\s/)) {
      flags.refreshGameInfo = true;
    }

    // Detect lockId/itemId changes so it forces a game info update
    if (currentUpdate.lockId || currentUpdate.itemId) {
      flags.refreshGameInfo = true;
    }

    const childLayout = layout ?? element.layout;
    const updatedElement: GameElement = _replace
      ? {
          // updatable properties
          ...currentUpdate,

          // updatable but ensuring minimal data
          enabled: enabled ?? true,
          name: element.name,
          properties: { ...properties },
          stage: { ...element.stage, ...stage },
          translations: { ...translations },
          layout: childLayout && findAndUpdate(childLayout, updates, flags),

          // fixed properties
          id: element.id,
          type: element.type,
          component: element.component,

          _updatedAt: Date.now()
        }
      : mergeGameData(element, { ...currentUpdate, _updatedAt: Date.now() });

    // Normal update
    return [...result, updatedElement];
  }, []);

  return updatedLayout.sort((a, b) => (newIndexes[a.id] || 0) - (newIndexes[b.id] || 0));
}

export const cleanupElement = function <T extends Partial<GameElement>>(element: T): T {
  // Remove properties that must be unique
  const copy: T = { ...element };

  delete copy.id;
  delete copy._id;
  delete copy._updatedAt;
  delete copy.lockId;
  delete copy.itemId;
  delete copy.actions;
  delete copy.actionsFail;

  return copy;
};
