import { useContext } from "react";
import { Box, styled } from "@mui/material";
import { useDrag } from "react-dnd";

import { getAssetUrl } from "@shared/utils/plugins";
import { ComponentContext } from "@shared/game-player";

import type { EditorComponentDropItem } from "@app/types";
import { Image } from "@app/components";
import { useEditor } from "@app/editor";

import QuestionMarkIcon from "@mui/icons-material/HelpOutline";

interface Props {
  item: EditorComponentDropItem;
  onSelect?: (id: string, data: EditorComponentDropItem) => void;
  onDragStart?: (item: EditorComponentDropItem) => void;
  onDragEnd?: () => void;
}

export const ComponentItemBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(4, 2),
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,

  transition: theme.transitions.create(["background-color", "transform"], {
    duration: theme.transitions.duration.shortest
  }),

  "&:hover": {
    backgroundColor: theme.palette.action.disabledBackground,

    ">.MuiCard-root:hover": {
      transform: "none"
    }
  }
}));

const ComponentItemImage = styled(Image)({
  flex: "1 1 auto",
  width: "100%",
  height: "100%",
  objectFit: "contain"
});

const ComponentItemText = styled("div")(({ theme }) => ({
  flex: "0 0 auto",
  marginTop: theme.spacing(2),
  textAlign: "center",
  fontSize: "0.8rem",
  wordBreak: "break-word"
}));

export function ComponentPickerItem({ item, onSelect, onDragStart, onDragEnd }: Props) {
  const { gameDefinition } = useEditor();
  const { pluginsBaseUrl, builtinPluginsBaseUrl } = useContext(ComponentContext);

  // Drag component
  const [, drag] = useDrag<EditorComponentDropItem>(() => ({
    type: item.component.type,
    item: () => {
      // Needs a small delay?
      if (onDragStart) {
        setTimeout(() => onDragStart(item), 1);
      }

      return item;
    },
    end: onDragEnd
  }));

  // Calculate the thumbnail.
  const thumbnail = item.thumbnail || item.manifest?.thumbnail || item.component.thumbnail;
  const thumbnailUrl = getAssetUrl(thumbnail, item.template?.properties || {}, {
    assetBaseUrl: gameDefinition?.assetBaseUrl || "",
    pluginBaseUrl: `${item.component.builtIn ? builtinPluginsBaseUrl : pluginsBaseUrl}${item.component.basePath}`,
    version: item.component.version
  });

  return (
    <ComponentItemBox onClick={() => onSelect?.(item.component.uuid, item)} ref={drag}>
      {thumbnailUrl ? (
        <ComponentItemImage src={thumbnailUrl} alt={item.name} />
      ) : (
        <QuestionMarkIcon fontSize='large' sx={{ opacity: 0.3 }} />
      )}
      <ComponentItemText>{item.name}</ComponentItemText>
    </ComponentItemBox>
  );
}
