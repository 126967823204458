import { Box, styled } from "@mui/material";
import { type Accept, useDropzone } from "react-dropzone";

import { useTranslation } from "@app/hooks";

import AddIcon from "@mui/icons-material/AddCircleOutline";

const ComponentUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  color: theme.palette.divider,
  marginBottom: theme.spacing(2),

  "&:hover": {
    backgroundColor: theme.palette.action.focus
  },

  "&.dragging": {
    backgroundColor: theme.palette.action.hover
  }
}));

interface Props {
  accept?: Accept;
  onDrop?: (files: File[]) => void;
}

export function ComponentUpload(props: Props) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    onDrop: props.onDrop
  });

  const { t } = useTranslation();

  return (
    <ComponentUploadBox {...getRootProps()} className={isDragActive ? "dragging" : ""}>
      <AddIcon fontSize='large' />
      {t("editor.upload")}
      <input {...getInputProps()} />
    </ComponentUploadBox>
  );
}
