import { useCallback } from "react";

import { type Asset, GameElementCategory } from "@shared/game-engine";

import { useAppContext, useTranslation } from "@app/hooks";
import { ComponentPicker, useEditor } from "@app/editor";

const COMPONENT_LIST = ["image", "audio", "video"];
const SUBTYPE_LIST = [GameElementCategory.STATIC];

export default function EditorMultimediaPicker() {
  const { notify } = useAppContext();
  const { t } = useTranslation();
  const { gameDefinition, reloadAssets, uploadAssets, deleteAsset } = useEditor();

  const handleUpload = useCallback(
    async (files: File[]) => {
      if (!gameDefinition) return;

      const results = await uploadAssets(files);

      if (results.length < files.length) {
        notify(t("common.uploadSuccessWithErrors"), "warning");
      } else if (!results.length) {
        notify(t("common.uploadError"), "error");
      } else {
        notify(t("common.uploadSuccess"), "success");
      }

      reloadAssets();
    },
    [gameDefinition, notify, reloadAssets, t, uploadAssets]
  );

  const handleDelete = useCallback(
    async (asset: Asset) => {
      if (!gameDefinition) return;

      try {
        await deleteAsset(asset);
        notify(t("common.deleteSuccess"), "success");
      } catch (e) {
        notify(t("editor.assetDeleteError", { error: e instanceof Error ? e.message : "" }), "error");
        return;
      }

      reloadAssets();
    },
    [deleteAsset, gameDefinition, notify, reloadAssets, t]
  );

  return (
    <ComponentPicker
      builtin
      subtypes={SUBTYPE_LIST}
      uuids={COMPONENT_LIST}
      onUpload={handleUpload}
      onDelete={handleDelete}
      mode='flatten'
      search
    />
  );
}
