import { useAppContext, useBackend, useShortcuts, useTranslation } from "@app/hooks";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

// PSA: Do not put context providers here. Because Modals won't get them.
// Need to double check with generouted.
export default function AppLayout() {
  const { superPowers, enableSuperPowers, notify } = useAppContext();
  const { isAdmin } = useBackend();
  const { t } = useTranslation();

  useEffect(() => {
    if (superPowers) {
      notify(t("common.superPowersEnabled"), "🦸‍♂️");
    }
  }, [superPowers, notify, t]);

  useShortcuts({
    "cmd+shift+e": () => isAdmin && enableSuperPowers(true)
  });

  return (
    <main>
      <Outlet />
    </main>
  );
}
