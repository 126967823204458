import { useEffect, useState } from "react";

type SupportedEvent = KeyboardEvent | MouseEvent | TouchEvent;

type Callback = (ev: Event) => void;

type Shortcuts = {
  [key: string | number]: Callback | [Callback, Callback];
};

const Modifiers = ["ctrl", "shift", "alt", "meta", "cmd", "win"];
const MouseButtons = ["mouse-left", "mouse-middle", "mouse-right"];
const FingerCount = ["one-finger", "two-finger", "three-finger", "four-finger", "five-finger"];

// For Mac users, use Meta instead of Ctrl
const isMac = navigator.userAgent.includes("Mac");
const isTouchSupported = "TouchEvent" in window;

export const useShortcuts = (shortcuts: Shortcuts, scope?: HTMLElement) => {
  const [inScope, setInScope] = useState(false);

  useEffect(() => {
    const checkScope = (_event: SupportedEvent) => {
      if (!scope) {
        return true;
      }

      return inScope;
    };

    const checkModifier = (event: SupportedEvent, modifier: string) => {
      if (modifier === "ctrl" && (isMac ? event.metaKey : event.ctrlKey)) {
        return true;
      }

      if (modifier === "shift" && event.shiftKey) {
        return true;
      }

      if (modifier === "alt" && event.altKey) {
        return true;
      }

      if (["meta", "cmd", "win"].includes(modifier) && event.metaKey) {
        return true;
      }

      return false;
    };

    const checkShortcut = (event: SupportedEvent, key: string | number): boolean => {
      if (key === "any") {
        return true;
      }

      const [keyName, ...modifiers] = key.toString().toLowerCase().split(/\+/g).reverse();

      if (modifiers.length) {
        // Check modifiers
        if (modifiers.some((modifier) => !checkModifier(event, modifier))) {
          return false;
        }
      } else {
        // Check if any modifier is pressed and the current pressed key is not a modifier
        if (!Modifiers.includes(keyName) && Modifiers.some((modifier) => checkModifier(event, modifier))) {
          return false;
        }
      }

      if (event instanceof KeyboardEvent) {
        if (keyName === "space" && event.key === " ") {
          return true;
        }

        if (keyName === "ctrl" && event.key.toLowerCase() === (isMac ? "meta" : "control")) {
          return true;
        }

        if (keyName === event.key.toLowerCase()) {
          return true;
        }

        // Debug
        // console.debug(`"${keyName}"`, `"${event.key.toLowerCase()}"`);
      }

      if (event instanceof MouseEvent) {
        if (keyName === "mouse-any") {
          return true;
        }

        const mouseButton = MouseButtons.indexOf(keyName);

        if (mouseButton !== -1 && event.button === mouseButton) {
          return true;
        }
      }

      if (isTouchSupported && event instanceof TouchEvent) {
        const finger = FingerCount.indexOf(keyName);

        if (finger !== -1 && event.touches.length === finger) {
          return true;
        }
      }

      return false;
    };

    const handleKeyDown = (event: SupportedEvent) => {
      if (!checkScope(event)) {
        return;
      }

      Object.entries(shortcuts).forEach(([key, action]) => {
        if (checkShortcut(event, key)) {
          if (action && Array.isArray(action)) {
            action[0](event);
          } else {
            action(event);
          }
        }
      });
    };

    const handleKeyUp = (event: SupportedEvent) => {
      if (!checkScope(event)) {
        return;
      }

      Object.entries(shortcuts).forEach(([key, action]) => {
        if (checkShortcut(event, key)) {
          if (Array.isArray(action)) {
            action[1](event);
          }
        }
      });
    };

    const enterScope = () => {
      setInScope(true);
      scope?.classList.add("shortcut-focused");
    };

    const leaveScope = (event: MouseEvent | TouchEvent) => {
      if (scope && (scope.contains(event.target as Node) || scope === event.target)) {
        return;
      }

      setInScope(false);
      scope?.classList.remove("shortcut-focused");
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("mousedown", handleKeyDown);
    document.addEventListener("mouseup", handleKeyUp);
    // document.addEventListener("touchstart", handleKeyDown);
    // document.addEventListener("touchend", handleKeyUp);

    if (scope) {
      scope.addEventListener("mousedown", enterScope);
      // scope.addEventListener("touchstart", enterScope);
      document.addEventListener("mousedown", leaveScope);
      // document.addEventListener("touchstart", leaveScope);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mousedown", handleKeyDown);
      document.removeEventListener("mouseup", handleKeyUp);
      // document.removeEventListener("touchstart", handleKeyDown);
      // document.removeEventListener("touchend", handleKeyUp);

      if (scope) {
        scope.removeEventListener("mousedown", enterScope);
        // scope.removeEventListener("touchstart", enterScope);
        document.removeEventListener("mousedown", leaveScope);
        // document.removeEventListener("touchstart", leaveScope);
      }
    };
  }, [shortcuts, scope, inScope]);

  return null;
};
