import { useCallback, useEffect, useState } from "react";
import { Stack, Chip, useTheme } from "@mui/material";

import { type GameDefinitionDto } from "@shared/api-client";

import { useAppContext, useBackend, useTranslation } from "@app/hooks";
import { PageLoading, TemplateList } from "@app/components";

import { TemplateModal } from "@app/components/projects/TemplateModal";

export default function CreatePage() {
  const theme = useTheme();
  const { setPageTitle } = useAppContext();
  const { t } = useTranslation();
  const { gameManager, dashboard } = useBackend();

  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [templates, setTemplates] = useState<GameDefinitionDto[] | null>(null);

  const [selection, setSelection] = useState<GameDefinitionDto | undefined>();
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  useEffect(() => {
    setPageTitle(t("create.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  useEffect(() => {
    dashboard
      .getList("/templates")
      .then(({ items }) => {
        setTemplates(items);
      })
      .catch((error) => {
        throw new Error(error);
      });
  }, [dashboard]);

  useEffect(() => {
    if (!templates) {
      setCategories([]);
      return;
    }

    // Extract unique categories from current templates
    const categories = new Set<string>();
    templates.forEach((template) => template.category && categories.add(template.category));
    setCategories(Array.from(categories));
  }, [templates]);

  const create = useCallback(
    async (template?: GameDefinitionDto) => {
      return await gameManager.post("/", { templateUuid: template?.uuid });
    },
    [gameManager]
  );

  return (
    <>
      {categories?.length > 0 && (
        <Stack direction='row' spacing={2} marginBottom={theme.spacing(4)}>
          <Chip
            label={t("create.allCategories")}
            variant={!category ? "filled" : "outlined"}
            onClick={() => setCategory("")}
          />
          {categories.map((item) => (
            <Chip
              key={item}
              label={item}
              variant={category === item ? "filled" : "outlined"}
              onClick={() => setCategory(item)}
            />
          ))}
        </Stack>
      )}
      {templates && (
        <TemplateList
          onSelect={(template) => {
            setSelection(template);
            setSelectionModalOpen(true);
          }}
          templates={templates.filter((item) => !category || item.category === category)}
        />
      )}
      {!templates && <PageLoading />}
      {selection && (
        <TemplateModal
          open={selectionModalOpen}
          onClose={() => setSelectionModalOpen(false)}
          onConfirm={create}
          template={selection}
        />
      )}
    </>
  );
}
