import { type ReactElement } from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import AnimationIcon from "@mui/icons-material/Animation";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PaletteIcon from "@mui/icons-material/Palette";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import PuzzleIcon from "@mui/icons-material/Extension";
import AddIcon from "@mui/icons-material/Add";
import InIcon from "@mui/icons-material/SouthEast";
import OutIcon from "@mui/icons-material/NorthEast";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QuestionMarkIcon from "@mui/icons-material/HelpOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PhotoIcon from "@mui/icons-material/Photo";
import CategoryIcon from "@mui/icons-material/Category";

export const Icons: Record<string, ReactElement> = {
  // Add your icons here
  // For example:
  // "my-icon": <MyIconComponent />,

  add: <AddIcon />,
  remove: <CancelIcon />,
  settings: <SettingsIcon />,
  puzzle: <PuzzleIcon />,
  animation: <AnimationIcon />,
  play: <PlayCircleIcon />,
  color: <PaletteIcon />,
  info: <InfoOutlinedIcon />,
  question: <QuestionMarkIcon />,
  orientation: <FlipCameraAndroidIcon />,
  fullscreen: <FullscreenIcon />,
  in: <InIcon />,
  out: <OutIcon />,
  language: <LanguageIcon />,
  success: <CheckCircleIcon />,
  fail: <CancelIcon />,
  edit: <EditNoteIcon />,
  photo: <PhotoIcon />,
  category: <CategoryIcon />,
};
