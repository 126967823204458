import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "@app/router";

import { Button, type RegistrationFormData } from "@app/components";
import { RegistrationForm } from "@app/components";
import { useBackend, useTranslation } from "@app/hooks";

export default function LoginPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (data: RegistrationFormData): Promise<boolean> => {
    setError("");
    try {
      await auth.post("/register", {
        email: data.email,
        password: data.password,
        username: data.username
      });

      setSuccess(true);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("register.error").toString());
      }

      return false;
    }
  };

  if (!success) {
    return (
      <>
        <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
          {t("home.createAccount")}
        </Typography>

        <RegistrationForm errors={error ? { root: error } : undefined} onSubmit={handleSubmit} />

        <Box display='block' sx={{ marginTop: theme.spacing(1), width: "100%" }}>
          <Typography display='block' textAlign='center'>
            {t("registration.alreadyAccount")} <Link to='/auth/login'>{t("registration.goToLogin")}</Link>
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("registration.success")}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: theme.spacing(6) }}>
        {t("registration.successMessage")}
      </Typography>

      <Link to='/auth/login'>
        <Button variant='contained' color='primary' fullWidth>
          {t("registration.continue")}
        </Button>
      </Link>
    </>
  );
}
